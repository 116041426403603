import { createSlice } from '@reduxjs/toolkit';

import { showToast } from '@/app/toasts/utils/showToast';
import { apiPost, handleRuntimeError } from '@/core/api';

import { hideModal } from '../../modals/models/modals';
import { NAME } from '../constants';
import { ReportType } from '../types';

import type { ExportFormat, TriggerExportProps } from '../types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    option: ExportFormat;
}

const initialState: State = { loading: false, option: 'csv' };

export const leadExportSlice = createSlice({
    name: `${NAME}/leadExport`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setExportOption(state, action: PayloadAction<ExportFormat>) {
            return {
                ...state,
                option: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setLoading, setExportOption, reset } = leadExportSlice.actions;

// === Selectors ======

export const getLoading = (state: AppState) => state[NAME]?.leadExportReducer?.loading;
export const getExportOption = (state: AppState) => state[NAME]?.leadExportReducer?.option;

// === Thunks ======

export const triggerExport =
    ({
        campaignId,
        email,
        exportType,
        reportType = ReportType.leads_only,
    }: TriggerExportProps): AppThunk =>
    async (dispatch) => {
        const payload: Omit<TriggerExportProps, 'exportType'> = {
            email,
            campaignId,
        };

        if (exportType === 'csv') {
            payload.reportType = reportType;
        }

        dispatch(setLoading(true));

        try {
            await apiPost(exportType === 'csv' ? '/reports/session' : '/reports/upload', {
                data: payload,
            });

            dispatch(hideModal());
            showToast({
                message: `${NAME}:export-created`,
                description: `${NAME}:export-created-success`,
                type: 'success',
            });
        } catch (err) {
            handleRuntimeError(err, { debugMessage: 'trigger export request failed:' });
        } finally {
            dispatch(setLoading(false));
        }
    };

export default leadExportSlice.reducer;
