export const CSVIcon = () => (
    <svg width="56" height="68" viewBox="0 0 56 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.375 60V8C0.375 3.78883 3.78883 0.375 8 0.375H35.7419C37.7328 0.375 39.6447 1.15363 41.0691 2.54451L53.3271 14.5141C54.7965 15.949 55.625 17.9159 55.625 19.9696V60C55.625 64.2112 52.2112 67.625 48 67.625H8C3.78883 67.625 0.375 64.2112 0.375 60Z"
            fill="white"
            stroke="#D1D5DB"
            strokeWidth="0.75"
        />
        <mask
            id="mask0_4667_50030"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="56"
            height="68"
        >
            <path
                d="M0.375 60V8C0.375 3.78883 3.78883 0.375 8 0.375H35.7419C37.7328 0.375 39.6447 1.15363 41.0691 2.54451L53.3271 14.5141C54.7965 15.949 55.625 17.9159 55.625 19.9696V60C55.625 64.2112 52.2112 67.625 48 67.625H8C3.78883 67.625 0.375 64.2112 0.375 60Z"
                fill="white"
                stroke="#D1D5DB"
                strokeWidth="0.75"
            />
        </mask>
        <g mask="url(#mask0_4667_50030)">
            <mask
                id="mask1_4667_50030"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="56"
                height="68"
            >
                <path
                    d="M0.375 60V8C0.375 3.78883 3.78883 0.375 8 0.375H35.7419C37.7328 0.375 39.6447 1.15363 41.0691 2.54451L53.3271 14.5141C54.7965 15.949 55.625 17.9159 55.625 19.9696V60C55.625 64.2112 52.2112 67.625 48 67.625H8C3.78883 67.625 0.375 64.2112 0.375 60Z"
                    fill="white"
                    stroke="#D1D5DB"
                    strokeWidth="0.75"
                />
            </mask>
            <g mask="url(#mask1_4667_50030)">
                <g opacity="0.1" filter="url(#filter0_ddd_4667_50030)">
                    <path
                        d="M40.25 17.8496H73.6063C76.3 17.8496 77.6288 14.5747 75.6966 12.6978L42.3404 -19.7055C40.4383 -21.5532 37.25 -20.2054 37.25 -17.5536V14.8496C37.25 16.5065 38.5931 17.8496 40.25 17.8496Z"
                        fill="white"
                    />
                </g>
            </g>
            <path
                d="M0.375 60V8C0.375 3.78883 3.78883 0.375 8 0.375H35.7419C37.7328 0.375 39.6447 1.15363 41.0691 2.54451L53.3271 14.5141C54.7965 15.949 55.625 17.9159 55.625 19.9696V60C55.625 64.2112 52.2112 67.625 48 67.625H8C3.78883 67.625 0.375 64.2112 0.375 60Z"
                stroke="#D1D5DB"
                strokeWidth="0.75"
            />
        </g>
        <path
            d="M20.5 28.375C20.5 26.0968 22.3468 24.25 24.625 24.25C26.9032 24.25 28.75 26.0968 28.75 28.375C28.75 30.6532 26.9032 32.5 24.625 32.5C22.3468 32.5 20.5 30.6532 20.5 28.375Z"
            fill="currentColor"
        />
        <path
            d="M30.25 30.625C30.25 28.761 31.761 27.25 33.625 27.25C35.489 27.25 37 28.761 37 30.625C37 32.489 35.489 34 33.625 34C31.761 34 30.25 32.489 30.25 30.625Z"
            fill="currentColor"
        />
        <path
            d="M17.5 41.125C17.5 37.19 20.69 34 24.625 34C28.56 34 31.75 37.19 31.75 41.125V41.1276C31.75 41.1674 31.7496 41.2074 31.749 41.2469C31.7446 41.5054 31.6074 41.7435 31.3859 41.8768C29.4107 43.0661 27.0966 43.75 24.625 43.75C22.1534 43.75 19.8393 43.0661 17.8641 41.8768C17.6426 41.7435 17.5054 41.5054 17.501 41.2469C17.5003 41.2064 17.5 41.1657 17.5 41.125Z"
            fill="currentColor"
        />
        <path
            d="M33.2498 41.1281C33.2498 41.1762 33.2494 41.2244 33.2486 41.2722C33.2429 41.6108 33.1612 41.9378 33.0157 42.232C33.2172 42.2439 33.4203 42.25 33.6248 42.25C35.2205 42.25 36.732 41.8803 38.0764 41.2213C38.3234 41.1002 38.4843 40.8536 38.4957 40.5787C38.4984 40.5111 38.4998 40.4432 38.4998 40.375C38.4998 37.6826 36.3172 35.5 33.6248 35.5C32.8784 35.5 32.1711 35.6678 31.5387 35.9676C32.6135 37.4061 33.2498 39.1912 33.2498 41.125V41.1281Z"
            fill="currentColor"
        />
        <defs>
            <filter
                id="filter0_ddd_4667_50030"
                x="24.75"
                y="-25.8105"
                width="57.1123"
                height="56.1602"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="-4.5" dy="4.5" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4667_50030"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="-0.75" dy="0.75" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_4667_50030"
                    result="effect2_dropShadow_4667_50030"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="-0.75" dy="0.75" />
                <feGaussianBlur stdDeviation="1.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend
                    mode="normal"
                    in2="effect2_dropShadow_4667_50030"
                    result="effect3_dropShadow_4667_50030"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect3_dropShadow_4667_50030"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
