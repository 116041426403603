export const ZIPIcon = () => (
    <svg width="84" height="60" viewBox="0 0 84 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M82.125 40.875H1.875V6C1.875 2.8934 4.3934 0.375 7.5 0.375H28.0863C30.4082 0.375 32.632 1.31114 34.2548 2.9717L35.1429 3.88043C36.3894 5.15594 38.0976 5.875 39.881 5.875H76.5C79.6066 5.875 82.125 8.3934 82.125 11.5V40.875Z"
            fill="url(#paint0_linear_4667_49947)"
            stroke="#D1D5DB"
            strokeWidth="0.75"
        />
        <path
            d="M1.85165 54.1967L0.592046 18.1967C0.480719 15.0149 3.02989 12.375 6.21361 12.375H77.7863C80.97 12.375 83.5192 15.015 83.4078 18.1968L82.1474 54.1968C82.0414 57.225 79.5559 59.625 76.5258 59.625H7.47321C4.44316 59.625 1.9576 57.2249 1.85165 54.1967Z"
            fill="white"
            stroke="#D1D5DB"
            strokeWidth="0.75"
        />
        <path
            d="M37.5 26.375C37.5 25.3395 38.3395 24.5 39.375 24.5H39.75C41.8211 24.5 43.5 26.1789 43.5 28.25V30.125C43.5 31.1605 44.3395 32 45.375 32H47.25C49.3211 32 51 33.6789 51 35.75V39.125C51 40.1605 50.1605 41 49.125 41H39.375C38.3395 41 37.5 40.1605 37.5 39.125V26.375Z"
            fill="currentColor"
        />
        <path
            d="M45 28.25C45 26.9369 44.518 25.7365 43.7212 24.8159C47.1201 25.7038 49.7962 28.3799 50.6841 31.7788C49.7635 30.982 48.5631 30.5 47.25 30.5H45.375C45.1679 30.5 45 30.3321 45 30.125V28.25Z"
            fill="currentColor"
        />
        <path
            d="M34.875 29H36V39.125C36 40.989 37.511 42.5 39.375 42.5H46.5V43.625C46.5 44.6605 45.6605 45.5 44.625 45.5H34.875C33.8395 45.5 33 44.6605 33 43.625V30.875C33 29.8395 33.8395 29 34.875 29Z"
            fill="currentColor"
        />
        <defs>
            <linearGradient
                id="paint0_linear_4667_49947"
                x1="42"
                y1="0"
                x2="42"
                y2="11.25"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#FAFAFA" />
            </linearGradient>
        </defs>
    </svg>
);
