export const XLXSIcon = () => (
    <svg width="56" height="68" viewBox="0 0 56 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.375 60V8C0.375 3.78883 3.78883 0.375 8 0.375H35.7419C37.7328 0.375 39.6447 1.15363 41.0691 2.54451L53.3271 14.5141C54.7965 15.949 55.625 17.9159 55.625 19.9696V60C55.625 64.2112 52.2112 67.625 48 67.625H8C3.78883 67.625 0.375 64.2112 0.375 60Z"
            fill="white"
            stroke="#D1D5DB"
            strokeWidth="0.75"
        />
        <mask
            id="mask0_4793_35274"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="56"
            height="68"
        >
            <path
                d="M0.375 60V8C0.375 3.78883 3.78883 0.375 8 0.375H35.7419C37.7328 0.375 39.6447 1.15363 41.0691 2.54451L53.3271 14.5141C54.7965 15.949 55.625 17.9159 55.625 19.9696V60C55.625 64.2112 52.2112 67.625 48 67.625H8C3.78883 67.625 0.375 64.2112 0.375 60Z"
                fill="white"
                stroke="#D1D5DB"
                strokeWidth="0.75"
            />
        </mask>
        <g mask="url(#mask0_4793_35274)">
            <mask
                id="mask1_4793_35274"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="56"
                height="68"
            >
                <path
                    d="M0.375 60V8C0.375 3.78883 3.78883 0.375 8 0.375H35.7419C37.7328 0.375 39.6447 1.15363 41.0691 2.54451L53.3271 14.5141C54.7965 15.949 55.625 17.9159 55.625 19.9696V60C55.625 64.2112 52.2112 67.625 48 67.625H8C3.78883 67.625 0.375 64.2112 0.375 60Z"
                    fill="white"
                    stroke="#D1D5DB"
                    strokeWidth="0.75"
                />
            </mask>
            <g mask="url(#mask1_4793_35274)">
                <g opacity="0.1" filter="url(#filter0_ddd_4793_35274)">
                    <path
                        d="M40.25 17.8496H73.6063C76.3 17.8496 77.6288 14.5747 75.6966 12.6978L42.3404 -19.7055C40.4383 -21.5532 37.25 -20.2054 37.25 -17.5536V14.8496C37.25 16.5065 38.5931 17.8496 40.25 17.8496Z"
                        fill="white"
                    />
                </g>
            </g>
            <path
                d="M0.375 60V8C0.375 3.78883 3.78883 0.375 8 0.375H35.7419C37.7328 0.375 39.6447 1.15363 41.0691 2.54451L53.3271 14.5141C54.7965 15.949 55.625 17.9159 55.625 19.9696V60C55.625 64.2112 52.2112 67.625 48 67.625H8C3.78883 67.625 0.375 64.2112 0.375 60Z"
                stroke="#D1D5DB"
                strokeWidth="0.75"
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5 27.625C17.5 26.5895 18.3395 25.75 19.375 25.75H36.625C37.6605 25.75 38.5 26.5895 38.5 27.625V40.375C38.5 41.4105 37.6605 42.25 36.625 42.25H19.375C18.3395 42.25 17.5 41.4105 17.5 40.375V27.625ZM37 31.375C37 31.1679 36.8321 31 36.625 31H29.125C28.9179 31 28.75 31.1679 28.75 31.375V32.875C28.75 33.0821 28.9179 33.25 29.125 33.25H36.625C36.8321 33.25 37 33.0821 37 32.875V31.375ZM37 35.125C37 34.9179 36.8321 34.75 36.625 34.75H29.125C28.9179 34.75 28.75 34.9179 28.75 35.125V36.625C28.75 36.8321 28.9179 37 29.125 37H36.625C36.8321 37 37 36.8321 37 36.625V35.125ZM37 38.875C37 38.6679 36.8321 38.5 36.625 38.5H29.125C28.9179 38.5 28.75 38.6679 28.75 38.875V40.375C28.75 40.5821 28.9179 40.75 29.125 40.75H36.625C36.8321 40.75 37 40.5821 37 40.375V38.875ZM26.875 40.75C27.0821 40.75 27.25 40.5821 27.25 40.375V38.875C27.25 38.6679 27.0821 38.5 26.875 38.5H19.375C19.1679 38.5 19 38.6679 19 38.875V40.375C19 40.5821 19.1679 40.75 19.375 40.75H26.875ZM19.375 37H26.875C27.0821 37 27.25 36.8321 27.25 36.625V35.125C27.25 34.9179 27.0821 34.75 26.875 34.75H19.375C19.1679 34.75 19 34.9179 19 35.125V36.625C19 36.8321 19.1679 37 19.375 37ZM19.375 33.25H26.875C27.0821 33.25 27.25 33.0821 27.25 32.875V31.375C27.25 31.1679 27.0821 31 26.875 31H19.375C19.1679 31 19 31.1679 19 31.375V32.875C19 33.0821 19.1679 33.25 19.375 33.25Z"
            fill="currentColor"
        />
        <defs>
            <filter
                id="filter0_ddd_4793_35274"
                x="24.75"
                y="-25.8105"
                width="57.1123"
                height="56.1602"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="-4.5" dy="4.5" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4793_35274"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="-0.75" dy="0.75" />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
                <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_4793_35274"
                    result="effect2_dropShadow_4793_35274"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="-0.75" dy="0.75" />
                <feGaussianBlur stdDeviation="1.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
                <feBlend
                    mode="normal"
                    in2="effect2_dropShadow_4793_35274"
                    result="effect3_dropShadow_4793_35274"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect3_dropShadow_4793_35274"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
